
import { Toast } from 'vant'
import { defineComponent, ref } from 'vue'

export default defineComponent({
  setup: (props, { emit }) => {
    const reviewText = ref('')
    const showEmojiSelector = ref(false)
    const emojis = Array(80).fill(0).map((_, i) => String.fromCodePoint(128512 + i))
    const start = ref(0)
    const setStart = () => {
      const input = document.querySelector<HTMLInputElement>('.fixed-bottom-comment-input input.van-field__control')
      if (input) {
        start.value = input.selectionStart || 0
        // console.log(start.value)
      }
    }
    return {
      reviewText,
      emojis,
      showEmojiSelector,
      sendReview: () => {
        if (!reviewText.value) {
          Toast.fail('评价内容不可为空！')
          return
        }
        emit('send', reviewText.value, () => {
          reviewText.value = ''
        })
      },
      insertEmoji: (e: string) => {
        reviewText.value = `${reviewText.value.slice(0, start.value)}${e}${reviewText.value.slice(start.value)}`
        showEmojiSelector.value = false
      },
      setStart
    }
  }
})
